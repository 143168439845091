const React = require('react');
const { Head } = require('nordic/head');
const PropTypes = require('prop-types');
const useI18n = require('nordic/i18n/useI18n');

const CrawlerHead = (props) => {
  const { i18n } = useI18n(props);
  const { crawlerData, oldCrawlerData } = props;
  const isFromInstagram = oldCrawlerData.fromInstagram;
  const title = crawlerData?.brandName
    ? i18n.gettext(
      'Paga {0} a {1}',
      oldCrawlerData.price,
      crawlerData.brandName,
    )
    : oldCrawlerData.title;
  const linkTitle = crawlerData?.title;
  const description = linkTitle || oldCrawlerData.description;

  return (
    <Head>
      <meta property="og:url" content={oldCrawlerData.url} />
      {!isFromInstagram && (
        <meta property="og:site_name" content={oldCrawlerData.site_name} />
      )}
      {!isFromInstagram && <meta property="og:title" content={title} />}
      {!isFromInstagram && (
        <meta property="og:description" content={description} />
      )}
      {!isFromInstagram && <meta property="og:type" content="article" />}
      {!isFromInstagram && (
        <meta property="og:image" content={oldCrawlerData.image} />
      )}
      {!isFromInstagram && oldCrawlerData.width && (
        <meta property="og:image:width" content={oldCrawlerData.width} />
      )}
      {!isFromInstagram && oldCrawlerData.height && (
        <meta property="og:image:height" content={oldCrawlerData.height} />
      )}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@mercadopago" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@mercadopago" />
      <meta name="twitter:image" content={oldCrawlerData.image} />
      <meta httpEquiv="cache-control" content="no-cache" />
      <meta httpEquiv="pragma" content="no-cache" />

      <title>{i18n.gettext('Botón y link de pago')}</title>
    </Head>
  );
};

CrawlerHead.propTypes = {
  crawlerData: PropTypes.shape({
    brandName: PropTypes.string,
    pictureUrl: PropTypes.string,
    siteId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
  oldCrawlerData: PropTypes.shape({
    image: PropTypes.string,
    price: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    site_name: PropTypes.string,
    fromInstagram: PropTypes.bool,
  }).isRequired,
};

module.exports = CrawlerHead;
