const React = require('react');
const PropTypes = require('prop-types');
const { Script } = require('nordic/script');
const Page = require('nordic/page');
const CrawlerHead = require('../../components/CrawlerHead');
const { useViewController } = require('./hooks/useViewController');

const View = (props) => {
  const { crawlerData, oldCrawlerData } = props;
  const analytics = {
    section: 'payment-link',
    page: '/v1/redirect',
  };

  useViewController(props);

  return (
    <Page name="redirect" state={props} analytics={analytics}>
      <CrawlerHead crawlerData={crawlerData} oldCrawlerData={oldCrawlerData} />
      <Script src="vendor.js" />
    </Page>
  );
};


View.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  crawlerData: PropTypes.shape({
    brandName: PropTypes.string,
    pictureUrl: PropTypes.string,
    siteId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
  oldCrawlerData: PropTypes.shape({
    image: PropTypes.string,
    price: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    site_name: PropTypes.string,
    fromInstagram: PropTypes.bool,
  }).isRequired,
};

module.exports = View;
